export const testimonialsData = [
    "This is what the official app should be.",
    "I have spent so many hours trying to make iPhone screen schedules of my selects. This is bonkers. Thank you!",
    "Thank you!!! I used to make these schedules with Photoshop back in the day. Nice color palette!! 💜",
    "So I checked it out and this is AWESOME! Looks very clean and I love the aesthetics and details.",
    "Grid looks sick",
    "As an aspiring web developer, this is mind blowing. Thanks for this",
    "Game Changer 💯 Thank you! 🫶",
    "This is actually the greatest thing ever",
    "you nailed it. Does exactly the thing it needs to do, nothing more, nothing less.",
    "Oooo I love the 'one tap-interested' and '2 taps-must see'",
    "Very clean layout I like it",
    "oh this is SICK i love this",
    "That heatmap festure is sick",
    "This is AMAZINNNGGGGG!!!",
    "been looking for something like this thanks.",
    "Mate this is banging for the adhd brain - being able to tier want and must see across the day is killer.",
    "Genius! Thank you for this!!",
    "This is seriously nice. Great job!",
    "Genius idea. How do I invest?",
    "Fuck yes thank you",
    "Was skeptical At first but gave it a shot. Really love it! Very good UI!",
    "This is pretty awesome actually. Thank you very much!",
    "This is amazing. I usually screenshot the lineup and draw on it with digital marker. But this is soooooo much nicer! 🫶🫶🫶",
    "I want to go to a festival just to use this planner. Good job 👏",
    "this is literally perfect!!!",
    "Dude this is so cool, thank you!! /trashes the horrible google sheet I was painfully making",
    "Fuck yeah bud this what we needed",
    "Omg how cool is this!!! I was just wondering why there wasn't a way to share your schedule on the NC app",
    "This is amazing!! I love it!!",
    "This is amazing, beautifully done"
]

export const testimonialsDataLess = [
    "This is what the official app should be.",
    "I have spent so many hours trying to make iPhone screen schedules of my selects. This is bonkers. Thank you!",
    "Thank you!!! I used to make these schedules with Photoshop back in the day. Nice color palette!! 💜",
    "So I checked it out and this is AWESOME! Looks very clean and I love the aesthetics and details.",
    "As an aspiring web developer, this is mind blowing. Thanks for this",
    "Game Changer 💯 Thank you! 🫶",
    "This is actually the greatest thing ever",
    "you nailed it. Does exactly the thing it needs to do, nothing more, nothing less.",
    "Oooo I love the 'one tap-interested' and '2 taps-must see'",
    "That heatmap festure is sick",
    "been looking for something like this thanks.",
    "Mate this is banging for the adhd brain - being able to tier want and must see across the day is killer.",
    "Genius! Thank you for this!!",
    "This is seriously nice. Great job!",
    "Was skeptical At first but gave it a shot. Really love it! Very good UI!",
    "This is amazing. I usually screenshot the lineup and draw on it with digital marker. But this is soooooo much nicer! 🫶🫶🫶",
    "this is literally perfect!!!",
    "Dude this is so cool, thank you!! /trashes the horrible google sheet I was painfully making",
    "Fuck yeah bud this what we needed",
    "Omg how cool is this!!! I was just wondering why there wasn't a way to share your schedule on the NC app",
    "This is amazing, beautifully done"
]