import './App.css'
import React from 'react'
import ReactGA from 'react-ga4'

import Home from './pages/Home'

ReactGA.initialize('G-0307FGZWFQ')

function App() {
  ReactGA.send('pageview')
  
  return (
    <div className="App">
        <Home />
    </div>
  )
}

export default App