import React, { useState, useRef } from 'react'
import Testimonial from '../components/Testimonial/Testimonial'
import { IconArrowUpRight } from '@tabler/icons-react'
import { testimonialsData, testimonialsDataLess } from '../constants'
import { getRandomClickCount, generateCurves } from '../helpers'
import { ReactP5Wrapper } from '@p5-wrapper/react'
import ReactGA from 'react-ga4'

const FestiPlannr = () => {
    const windowWidth = window.innerWidth
    const [testimonials, setTestimonials] = useState(
        windowWidth > 480 ? 
            testimonialsData.map(text => ({
                text,
                clickCount: getRandomClickCount()
            })) : testimonialsDataLess.map(text => ({
                text,
                clickCount: getRandomClickCount()
            }))
    )
    const handleTestimonialClick = (index) => {
        setTestimonials(testimonials.map((t, i) => 
            i === index ? { ...t, clickCount: (t.clickCount + 1) % 3 } : t
        ))
        ReactGA.event({
            category: 'User',
            action: 'Clicked on Action',
            label: 'Testimonial block click'
        })
    }
    const resetAllToZero = () => { 
        setTestimonials(testimonials.map(t => ({ ...t, clickCount: 0 })))
        ReactGA.event({
            category: 'User',
            action: 'Clicked on Action',
            label: 'Clear'
        })
    }
    const randomizeAllClickCounts = () => { 
        setTestimonials(testimonials.map(t => ({ ...t, clickCount: getRandomClickCount() })))
        ReactGA.event({
            category: 'User',
            action: 'Clicked on Action',
            label: 'Click me!'
        })
    }

    const handleLinkClick = (link) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked on External Link',
            label: link
        })
    }

    const [allCurves] = useState(generateCurves())
    const festiPlannrRef = useRef(null)

    const [isP5Rendered, setIsP5Rendered] = useState(false)

    const sketch = (p) => {
        let symmetry = 18
        let angle = 360 / symmetry
    
        p.setup = () => {
            const sectionFestiPlannr = document.querySelector('.section-festiplannr')
            const sectionHeight = sectionFestiPlannr.getBoundingClientRect().height

            p.createCanvas(window.innerWidth, sectionHeight)
            setIsP5Rendered(true)
            p.noLoop()
        }

        p.windowResized = () => {
            const sectionFestiPlannr = document.querySelector('.section-festiplannr')
            const sectionHeight = sectionFestiPlannr.getBoundingClientRect().height

            p.resizeCanvas(window.innerWidth, sectionHeight)
            p.redraw()
            setIsP5Rendered(true)
        }
    
        p.draw = () => {
            p.clear()
            const sectionFestiPlannr = document.querySelector('.section-festiplannr')
            const sectionHeight = sectionFestiPlannr.getBoundingClientRect().height
            p.noFill()
            p.translate(7 * window.innerWidth / 8, sectionHeight / 2 - 120)
        
            const gradientColors = [
                { stop: 0, color: p.color('#FFA471') },
                { stop: 0.1464, color: p.color('#FFA471') },
                { stop: 0.50, color: p.color('#FF52AB') },
                { stop: 0.8536, color: p.color('#00A3FF') },
                { stop: 1, color: p.color('#00A3FF') }
            ]
        
            const getGradientColorWithOpacity = (t) => {
                for (let i = 0; i < gradientColors.length - 1; i++) {
                    if (t >= gradientColors[i].stop && t <= gradientColors[i + 1].stop) {
                        let startColor = gradientColors[i].color
                        let endColor = gradientColors[i + 1].color
                        let factor = (t - gradientColors[i].stop) / (gradientColors[i + 1].stop - gradientColors[i].stop)
                        let interpolatedColor = p.lerpColor(startColor, endColor, factor)
                        
                        let opacity = p.map(t, 0, 1, 32, 48)
                        
                        return p.color(
                            p.red(interpolatedColor),
                            p.green(interpolatedColor),
                            p.blue(interpolatedColor),
                            opacity
                        )
                    }
                }
                return gradientColors[gradientColors.length - 1].color
            }
        
            for (let j = 0; j < allCurves.length; j++) {
                for (let k = 0; k < symmetry; k++) {
                    p.rotate(angle)
                    let currCurve = allCurves[j]
        
                    for (let i = 0; i < currCurve.length - 1; i++) {
                        let currPoint = currCurve[i]
                        let nextPoint = currCurve[i + 1]
        
                        let t = i / (currCurve.length - 1)
        
                        let segmentColor = getGradientColorWithOpacity(t)
                        p.stroke(segmentColor)
        
                        p.beginShape()
                        p.curveVertex(currCurve[Math.max(0, i - 1)].x, currCurve[Math.max(0, i - 1)].y)
                        p.curveVertex(currPoint.x, currPoint.y)
                        p.curveVertex(nextPoint.x, nextPoint.y)
                        p.curveVertex(currCurve[Math.min(currCurve.length - 1, i + 2)].x, currCurve[Math.min(currCurve.length - 1, i + 2)].y) // control point
                        p.endShape()
        
                        p.fill(255, p.random(24, 64))
                        p.noStroke()
                        let circleSize = p.random(0.5, 3)
                        p.ellipse(currPoint.x, currPoint.y, circleSize, circleSize)
        
                        p.noFill()
                    }
                }
            }
        }         
    }

    return (
        <div className={`section-festiplannr ${!isP5Rendered ? 'fallback-bg' : ''}`} ref={festiPlannrRef}>
            <div className="section-wrapper">
                <a onClick={() => handleLinkClick("FestiPlannr section")} className="section-title-wrapper section-title-link" href='https://www.festiplannr.com' rel="noreferrer" target='_blank'>
                    <h2>festiplannr.com</h2>
                    <IconArrowUpRight size={28} strokeWidth={2} className="icon-link link-festiplannr"/>
                </a>
                <video
                    src="/assets/fp_compressed.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="section-video"
                />
                <div className="section-content">
                    <div className="fp-content-item">
                        <p>Created to make a better music festival scheduling experience. Built an MVP in 5 days in May, then spent 6 weeks across June/July designing and developing the v1. Launched on Aug 1, 2024.</p>
                    </div>
                    <div className="fp-content-item">
                        <p>FestiPlannr addresses the following gaps in existing tools:</p>
                        <div className="sl-list">
                            <div className="sl-list-item">
                                <p className="sl-list-bullet">–</p>
                                <p>A binary “yes/no” for denoting who you want to see is insufficient for expressing intent</p>
                            </div>
                            <div className="sl-list-item">
                                <p className="sl-list-bullet">–</p>
                                <p>Aggregating schedule data across multiple people is painstaking</p>
                            </div>
                            <div className="sl-list-item">
                                <p className="sl-list-bullet">–</p>
                                <p>Setting a schedule as your lock screen is useful, but creating the images is a highly manual process</p>
                            </div>
                        </div>
                    </div>
                    <div className="subsection-wrapper testimonials-header-wrapper">
                        <h3 className="header-text-festiplannr testimonials-header">Some reviews!</h3>
                        <button className="testimonial-button" onClick={randomizeAllClickCounts}>Click me!</button>
                        <button className="testimonial-button" onClick={resetAllToZero}>Clear</button>
                    </div>
                </div>
            </div>
            {windowWidth > 960 && (
                <div className="p5-wrapper">
                    <ReactP5Wrapper sketch={sketch} />
                </div>
            )}
            <div className="testimonials-wrapper">
                {testimonials.map((testimonial, index) => (
                    <Testimonial 
                        key={index} 
                        text={testimonial.text} 
                        clickCount={testimonial.clickCount} 
                        handleClick={() => handleTestimonialClick(index)}
                    />
                ))}
            </div>
        </div>
    )
}

export default FestiPlannr
