import React from 'react'
import './Testimonial.css'

const Testimonial = ({ text, clickCount, handleClick }) => {
    return (
        <div 
            className={`testimonial testimonial-${clickCount}`} 
            onClick={handleClick}
        >
            &#34;{text}&#34;
        </div>
    )
}

export default Testimonial
