import React, { useState } from 'react'
import { IconArrowUp, IconArrowUpRight } from '@tabler/icons-react'
import { ReactP5Wrapper } from '@p5-wrapper/react'
import { generateLines } from '../helpers'
import ReactGA from 'react-ga4'

const Everlaw = () => {
    const windowWidth = window.innerWidth
    const [allCurves] = useState(generateLines())

    const [isP5Rendered, setIsP5Rendered] = useState(false)

    const handleLinkClick = (link) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked on External Link',
            label: link
        })
    }
    const handleActionClick = (action) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked on Action',
            label: action
        })
    }

    const sketch = (p) => {
        let symmetry = 24
        let angle = 360 / symmetry
    
        p.setup = () => {
            const sectionEverlaw = document.querySelector('.section-everlaw')
            const sectionHeight = sectionEverlaw.getBoundingClientRect().height

            p.createCanvas(window.innerWidth, sectionHeight)
            setIsP5Rendered(true)
            p.noLoop()
        }

        p.windowResized = () => {
            const sectionEverlaw = document.querySelector('.section-everlaw')
            const sectionHeight = sectionEverlaw.getBoundingClientRect().height

            p.resizeCanvas(window.innerWidth, sectionHeight)
            p.redraw()
            setIsP5Rendered(true)
        }
    
        p.draw = () => {
            p.clear()
            const sectionEverlaw = document.querySelector('.section-everlaw')
            const sectionHeight = sectionEverlaw.getBoundingClientRect().height
            p.noFill()
            p.translate(window.innerWidth / 8, sectionHeight / 2 - 120)
        
            const gradientColors = [
                { stop: 0, color: p.color('#9d9393') },
                { stop: 0.1464, color: p.color('#afafac') },
                { stop: 0.50, color: p.color('#bcbbb7') },
                { stop: 0.8536, color: p.color('#d4d3ce') },
                { stop: 1, color: p.color('#F1F0EA') }
            ]
        
            const getGradientColorWithOpacity = (t) => {
                for (let i = 0; i < gradientColors.length - 1; i++) {
                    if (t >= gradientColors[i].stop && t <= gradientColors[i + 1].stop) {
                        let startColor = gradientColors[i].color
                        let endColor = gradientColors[i + 1].color
                        let factor = (t - gradientColors[i].stop) / (gradientColors[i + 1].stop - gradientColors[i].stop)
                        let interpolatedColor = p.lerpColor(startColor, endColor, factor)
                        
                        // Calculate opacity (255 to near 0)
                        let opacity = p.map(t, 0, 1, 9, 5)
                        
                        return p.color(
                            p.red(interpolatedColor),
                            p.green(interpolatedColor),
                            p.blue(interpolatedColor),
                            opacity
                        )
                    }
                }
                return gradientColors[gradientColors.length - 1].color
            }
        
            for (let j = 0; j < allCurves.length; j++) {
                for (let k = 0; k < symmetry; k++) {
                    p.rotate(angle)
                    let currCurve = allCurves[j]
        
                    for (let i = 0; i < currCurve.length - 1; i++) {
                        let currPoint = currCurve[i]
                        let nextPoint = currCurve[i + 1]
        
                        let t = i / (currCurve.length - 1)
        
                        let segmentColor = getGradientColorWithOpacity(t)
                        p.stroke(segmentColor)
        
                        p.beginShape()
                        p.vertex(currCurve[Math.max(0, i - 1)].x, currCurve[Math.max(0, i - 1)].y)
                        p.vertex(currPoint.x, currPoint.y)
                        p.vertex(nextPoint.x, nextPoint.y)
                        p.vertex(currCurve[Math.min(currCurve.length - 1, i + 2)].x, currCurve[Math.min(currCurve.length - 1, i + 2)].y) // control point
                        p.endShape()
        
                        p.fill(255, p.random(24, 64))
                        p.noStroke()
                        let circleSize = p.random(0.5, 3)
                        p.ellipse(currPoint.x, currPoint.y, circleSize, circleSize)
        
                        p.noFill()
                    }
                }
            }
        }         
    }  

    return (
        <div className={`section-wrapper section-everlaw ${!isP5Rendered ? 'fallback-bg-everlaw' : ''}`}>
            {windowWidth > 960 && (
                <div className="p5-wrapper p5-everlaw">
                    <ReactP5Wrapper sketch={sketch} />
                </div>
            )}
            <a onClick={() => handleLinkClick("Everlaw section")} className="section-title-wrapper section-title-link title-link-everlaw" href='https://www.everlaw.com' target='_blank' rel="noreferrer">
                <h2>Everlaw</h2>
                <IconArrowUpRight size={28} strokeWidth={2} className="icon-link link-everlaw"/>
            </a>
            <div className="section-content">
                <video
                    src="/assets/wa_compressed.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="section-video video-on-light"
                />
                <div className="section-content-item">
                    <p>Led design across the generative AI feature set (Everlaw<span style={{ verticalAlign: 'super', fontSize: '10px' }}>AI</span> Assistant), one of Everlaw’s top priorities for FY24/25. Either directly worked on or oversaw the design of every feature.</p>
                    <p>Feature set overview: <a onClick={() => handleLinkClick("EverlawAI blog")} className="link link-everlaw" href='https://www.everlaw.com/product/everlaw-ai-assistant' target='_blank' rel="noreferrer">Everlaw<span style={{ verticalAlign: 'super', fontSize: '10px' }}>AI</span> Assistant</a></p>
                </div>
                <div className="section-content-item">
                    <p>Designed Multi-Matter Models (AI) as the lead designer for the Predictive Coding platform area. This feature allows users to leverage ML models they've trained on existing data in new matters.</p>
                    <p>Blog post by <a onClick={() => handleLinkClick("MMM blog")} className="link link-everlaw" href='https://www.linkedin.com/in/justin-smith-2b5270126/' target='_blank' rel="noreferrer">Justin Smith</a>: <a className="link link-everlaw" href='https://www.everlaw.com/blog/ai-and-law/introducing-multi-matter-models-by-everlaw/' target='_blank' rel="noreferrer">Introducing Multi-Matter Models by Everlaw</a></p>
                </div>
                <video
                    src="/assets/cv_compressed.mp4"
                    autoPlay
                    loop
                    muted
                    playsInline
                    className="section-video video-on-light no-margin-top"
                />
                <div className="section-content-item">
                    <p>Designed Communication Visualizer (dataviz), an interactive network graph of communication data.</p>
                    <p>Blog post by <a onClick={() => handleLinkClick("Vivan")} className="link link-everlaw" href='https://www.linkedin.com/in/vivanmarwaha/' target='_blank' rel="noreferrer">Vivan Marwaha</a>: <a onClick={() => handleLinkClick("CommViz blog")} className="link link-everlaw" href='https://www.everlaw.com/blog/ediscovery-software/introducing-communication-visualizer/' target='_blank' rel="noreferrer">Introducing Communication Visualizer by Everlaw</a></p>
                    <p>This is what I sound like: <a onClick={() => handleLinkClick("CommViz video")} className="link link-everlaw" href='https://vimeo.com/849634956?share=copy#t=231.881' target='_blank' rel="noreferrer">Communication Visualizer: Conceptual overview</a></p>
                </div>
                <div className="back-to-top-wrapper" onClick={() => {
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    handleActionClick("Scroll to top")
                }}>
                    <IconArrowUp size={20} strokeWidth={1.5} className="back-to-top-icon" />
                    <button className="back-to-top-button" >
                        Back to top
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Everlaw
