export const getRandomClickCount = () => {
    const weights = [0.7, 0.2, 0.1]
    const cumulativeWeights = weights.reduce((acc, weight, i) => {
        if (i === 0) {
            acc.push(weight)
        } else {
            acc.push(weight + acc[i - 1])
        }
        return acc
    }, [])

    const random = Math.random()

    return cumulativeWeights.findIndex(weight => random < weight)
}

export const curveSegments = 48
const generateCurvePoints = () => {
    const curvePoints = []
    let startAngle = Math.random() * (360)
    let startRadius = Math.random() * 100
    let startX = Math.cos(startAngle) * startRadius
    let startY = Math.sin(startAngle) * startRadius

    const biasStrength = 0.68
    const minLength = 4
    const maxLength = 120

    for (let k = 0; k <= curveSegments; k++) {
        let nextX = Math.random() * (maxLength - minLength) + minLength
        let nextY = Math.random() * (maxLength - minLength) + minLength

        let xDirection = 1
        let yDirection = 1
        if (Math.random() > biasStrength) xDirection = -1
        if (Math.random() > biasStrength) yDirection = -1

        let x = startX + nextX * xDirection
        let y = startY + nextY * yDirection

        curvePoints.push({ x, y })
        startX = x
        startY = y
    }

    return curvePoints
}
export const generateCurves = () => {
    let allCurves = []
    let numCurves = 7
    for (let i = 0; i < numCurves; i++) {
        let curve = generateCurvePoints()
        allCurves.push(curve)
    }

    return allCurves
}

export const lineSegments = 80
const generateLinePoints = () => {
    const curvePoints = []
    let startAngle = Math.random() * 360
    let startX = Math.cos(startAngle)
    let startY = Math.sin(startAngle)

    const biasStrength = 0.89
    const length = 80

    for (let k = 0; k <= lineSegments; k++) {
        let nextX = length
        let nextY = length

        let xDirection = 1
        let yDirection = 1
        if (Math.random() > biasStrength) xDirection = -1
        if (Math.random() > biasStrength) yDirection = -1

        let x = (startX + nextX) * xDirection
        let y = (startY + nextY) * yDirection

        curvePoints.push({ x, y })
        startX = x
        startY = y
    }

    return curvePoints
}
export const generateLines = () => {
    let allCurves = []
    let numCurves = 4
    for (let i = 0; i < numCurves; i++) {
        let curve = generateLinePoints()
        allCurves.push(curve)
    }

    return allCurves
}