import React, { useState } from 'react'
import './Home.css'
import { IconArrowDown, IconMapPin, IconMail, IconBrandInstagram, IconBrandLinkedin, IconFileText, IconBrandGithub } from '@tabler/icons-react'
import FestiPlannr from './FestiPlannr'
import Everlaw from './Everlaw'
import ReactGA from 'react-ga4'

const Home = () => {
    const [scrollProgress, setScrollProgress] = useState(0)

    const handleLinkClick = (link) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked on External Link',
            label: link
        })
    }
    const handleActionClick = (action) => {
        ReactGA.event({
            category: 'User',
            action: 'Clicked on Action',
            label: action
        })
    }

    return (
        <div className="home-wrapper">
            <div className="splash-wrapper">
                <div className="splash-content-wrapper">
                    <div className="splash-left">
                        <h1>James Feng</h1>
                        <div className="splash-icons-wrapper">
                            <a onClick={() => handleLinkClick("Resume")} href="https://drive.google.com/file/d/1-gkM7l7Z4WVsci_FAtpdfKEn7qtW6uZS/view?usp=sharing" target="_blank" rel="noreferrer">
                                <IconFileText size={25} strokeWidth={1.68} className="splash-icon icon-resume" />
                            </a>
                            <a onClick={() => handleLinkClick("LinkedIn")} href="https://www.linkedin.com/in/james-feng/" target="_blank" rel="noreferrer">
                                <IconBrandLinkedin size={28} strokeWidth={1.5} className="splash-icon icon-linkedin" />
                            </a>
                            <a onClick={() => handleLinkClick("Github")} href="https://github.com/fengJames" target="_blank" rel="noreferrer">
                                <IconBrandGithub size={25} strokeWidth={1.68} className="splash-icon icon-github" />
                            </a>
                            <a onClick={() => handleLinkClick("Instagram")} href="https://www.instagram.com/famousjenga" target="_blank" rel="noreferrer">
                                <IconBrandInstagram size={28} strokeWidth={1.5} className="splash-icon icon-instagram" />
                            </a>
                        </div>
                        <div className="splash-info-items-wrapper">
                            <div className="splash-info-item">
                                <IconMapPin size={16} strokeWidth={1.7} />
                                <p className="splash-info-text">San Francisco, CA</p>
                            </div>
                            <div className="splash-info-item">
                                <IconMail size={16} strokeWidth={1.7} />
                                <p className="splash-info-text">jamesfeng123@gmail.com</p>
                            </div>
                        </div>
                        <div className="sl-list">
                            <div className="sl-list-item">
                                <div className="sl-list-bullet">–</div>
                                <p>Product designer, but also codes</p>
                            </div>
                            <div className="sl-list-item">
                                <div className="sl-list-bullet">–</div>
                                <p>Loves making stuff</p>
                            </div>
                            <div className="sl-list-item">
                                <div className="sl-list-bullet">–</div>
                                <p>
                                    <span>Big on&nbsp;</span>
                                    <a onClick={() => handleLinkClick("Penguins")} className="link link-penguins" href="https://platform.vox.com/wp-content/uploads/sites/2/chorus/uploads/chorus_asset/file/15231691/453801468.0.0.1421786380.jpg" target="_blank" rel="noreferrer">penguins</a>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="splash-divider" />
                    <div className="splash-right">
                        <div className="sr-item">
                            <div className="sr-item-wrapper">
                                <div className="blinker blinker-everlaw" />
                                <p className="sr-item-desc">
                                    <span>Senior Product Designer @&nbsp;</span>
                                    <a onClick={() => handleLinkClick("Everlaw splash")} className="link link-everlaw" href='https://www.everlaw.com' target='_blank' rel="noreferrer">Everlaw</a>
                                </p>
                            </div>
                            <p className="sr-item-dates">
                                Aug 2020 – Present
                            </p>
                        </div>
                        <div className="sr-item">
                            <div className="sr-item-wrapper">
                                <div className="blinker blinker-festiplannr" />
                                <p className="sr-item-desc">
                                    <span>Building&nbsp;</span>
                                    <a onClick={() => handleLinkClick("FestiPlannr splash")} className="link link-festiplannr" href='https://www.festiplannr.com' target='_blank' rel="noreferrer">festiplannr.com</a>
                                    <span>&nbsp;(side project)</span>
                                </p>
                            </div>
                            <p className="sr-item-dates">
                                May 2024 – Present
                            </p>
                        </div>
                        <div className="sr-item">
                            <p className="sr-item-desc">
                                <span>Designer, Developer @&nbsp;</span>
                                <a onClick={() => handleLinkClick("Berkeley splash")} className="link link-berkeley" href='https://www.econ.berkeley.edu/' target='_blank' rel="noreferrer">UC Berkeley School of Economics</a>
                            </p>
                            <p className="sr-item-dates">
                                Nov 2021 – Jan 2022
                            </p>
                        </div>
                        <div className="sr-item">
                            <p className="sr-item-desc">
                                <span>Studied computer science @&nbsp;</span>
                                <a onClick={() => handleLinkClick("UPenn splash")} className="link link-upenn" href='https://www.upenn.edu' target='_blank' rel="noreferrer">University of Pennsylvania</a>
                            </p>
                            <p className="sr-item-dates">
                                2016 – 2020
                            </p>
                        </div>
                    </div>
                </div>
                <div className="scroll-down-icon back-to-top-wrapper" onClick={() => {
                    document.querySelector('.section-festiplannr').scrollIntoView({ behavior: 'smooth' })
                    handleActionClick("Scroll to FestiPlannr")
                }}>
                    <IconArrowDown size={20} strokeWidth={1.5} className="back-to-top-icon" />
                    <button className="back-to-top-button" >
                        More on festiplannr.com, Everlaw
                    </button>
                </div>
            </div>
            <FestiPlannr 
                scrollProgress={scrollProgress}
                setScrollProgress={setScrollProgress}
            />
            <Everlaw />
        </div>
    )
}

export default Home
